
import React from 'react';
import './Blog.scss';

// Individuals news summary item
const Post = ({ post: { title, slug, subtitle, author } }) => (
    <a className="c-blog-item" href={ slug }>
        <figure className="c-blog-item-dot">&nbsp;</figure>
        <div className="c-blog-item-title">
            { title }
        </div>
        <span className="c-blog-item-author">
            { author }
        </span>
        <p className="c-blog-item-summary">
            { subtitle }
        </p>
        <button className="g-button on-white no-hover" href="/news/slug">
            Read More
        </button>
    </a>
)

// Main news block renderer
const Blog = ({ posts, headline, title = 'Latest News', readMore = false }) => (
    <section className="c-blog">
        {headline ? <>
            <h1 className="c-blog-title">{ headline }</h1>
            <h2 className="c-blog-subtitle">Documenting our journey in mobile security, implementing the most secure phone, selling source-available software and generating revenue through ethical partnerships.</h2>
        </>:
            <strong className="c-blog-title">{ title }</strong>}
        <div className="c-blog-content">
            {
                posts.map(
                    (post, index) => <Post key={ index } post={ post } />
                )
            }
        </div>
        {
            readMore === true &&
            <a href="/blog" className="g-button with-border on-white right-arrow">
                View all news
            </a>
        }
    </section>
);

export {
    Blog as default,
    Post
}
