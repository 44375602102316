
import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Global from '../layout/global';
import Blog from '../components/Blog';

import './index.scss';

/**
 * Index produces the homepage of the website by binding many
 * individual components with content from supplied Markdown.
 */
const Main = ({ data }) => {
    // Retrieve a list of blog posts
    const posts = data.allMdx.edges.map(
        (edge) => {
            return {
                title: edge.node.frontmatter.title,
                subtitle: edge.node.frontmatter.subtitle,
                author: edge.node.frontmatter.author,
                slug: edge.node.fields.slug
            };
        }
    );
    return (
        <Global
            page="index"
            description={ data.mdx.frontmatter.description }
            keyboards={ data.mdx.frontmatter.keywords }
            background={ data.mdx.frontmatter.background }>
            <MDXRenderer>{ data.mdx.body }</MDXRenderer>
            <Blog posts={ posts } readMore={ true } />
        </Global>
    );
};

export const pageQuery = graphql`
    query {
        mdx(frontmatter: { path: { eq: "/" } }) {
            body
            frontmatter {
                title
                date
                description
                keywords
                background
            }
        }
        allMdx(
            filter: {
                frontmatter: { layout: { eq: "post" } }
            }
            sort: {
                fields: [frontmatter___date],
                order: DESC
            }
            limit: 4
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        subtitle
                        author
                    }
                }
            }
        }
    }
`;

export default Main;
